import ProfessinalAgreementBanner from "./professional-agreement-banner";
import React, { useState, useEffect } from "react";

const ProfessinalAgreementBannerLayout = () => {
	// It's false because we don't want to show the banner, it's out of date
	const [open, setOpen] = useState(false);
	const [principalBannerOpen, setPrincipalBannerOpen] = useState(true);

	const onCloseClick = () => {
		if (typeof window !== "undefined") {
			window.sessionStorage.setItem("professionalAgreementBannerClosed", true);
			setOpen(false);
		}
	};

	useEffect(() => {
		if (
			typeof window !== "undefined" &&
			JSON.parse(
				window.sessionStorage.getItem("professionalAgreementBannerClosed")
			)
		) {
			setOpen(false);
		}
		if (
			typeof window !== "undefined" &&
			JSON.parse(window.sessionStorage.getItem("bannerClosed"))
		) {
			setPrincipalBannerOpen(false);
		}
	}, []);

	return (
		<ProfessinalAgreementBanner
			open={open}
			onCloseClick={onCloseClick}
			isBannerClosed={principalBannerOpen}
		/>
	);
};

export default ProfessinalAgreementBannerLayout;
