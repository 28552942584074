import React from 'react'
import BannerLayout from './professional-agreement-banner.styles'
import ClearIcon from '@material-ui/icons/Clear'
import PropTypes from 'prop-types'

const ProfessinalAgreementBanner = ({ open, onCloseClick, isBannerClosed }) => {
  return (
    <>
      {open && (
        <BannerLayout bannerIsClosed={isBannerClosed}>
          <BannerLayout.text>
            <b>NOTICE:</b> On <span className='small'>July 15, 2021</span>, we
            revised the Professional Services Agreement to make the terms easier
            to read and understand. If you have any questions about the above
            terms, please contact us. If you would like to see the previous
            Professional Services Agreement, please click{' '}
            <BannerLayout.Link to='/terms/professional-services-agreement-previous/'>
              here.
            </BannerLayout.Link>
          </BannerLayout.text>
          <BannerLayout.button onClick={onCloseClick}>
            <ClearIcon />
          </BannerLayout.button>
        </BannerLayout>
      )}
    </>
  )
}

ProfessinalAgreementBanner.propTypes = {
  open: PropTypes.bool,
  onCloseClick: PropTypes.func.isRequired
}

export default ProfessinalAgreementBanner
