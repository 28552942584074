import React from "react";
import Layout from "../../components/layout";
import PolicyDetails from "../../components/terms/professional-services-agreement";
import TermsGrid from "../../components/terms/terms-grid";
import SEO from "../../components/seo";
import ProfessionalAgreementBanner from "../../components/terms/professional-agreement-banner";

const ProfessionalServicesAgreement = () => {
	return (
		<Layout>
			<SEO
				title='Professional Services Agreement'
				pageType='home'
				flowType='none'
			/>
			{typeof window !== "undefined" && (
				<TermsGrid pathname={window.location.pathname} />
			)}
			<PolicyDetails />
			{/* TODO: update the display settings for
          when the banner component is not displayed
          it occupies its position
          - Now the banner isn't displayed
      */}
			<ProfessionalAgreementBanner />
		</Layout>
	);
};

export default ProfessionalServicesAgreement;
