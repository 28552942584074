import styled from 'styled-components'
import { theme, media } from '../../../helpers/theme'
import IconButton from '@material-ui/core/IconButton'
import { Link } from 'gatsby'

const ProfessionalAgreementBanner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  min-height: 50px;
  color: ${theme.colors.font.white};
  background: rgba(77, 77, 79, 0.8);
  position: fixed;
  opacity: 80%;
  z-index: 1;

  ${(props) =>
    !props.bannerIsClosed
      ? 'bottom:0px;'
      : `bottom: 70px;
      ${media(505)} {
        bottom: 54px;
      }
      ${media(923)} {
        bottom: 51px;
      }`}
`

const Text = styled.p`
  flex-grow: 1;
  justify-content: left;
  padding: 10px;
  text-align: left;
  font-size: ${theme.font.xs};
`

const CloseButton = styled(IconButton)`
  display: flex;
  justify-self: flex-end;
  .MuiSvgIcon-root {
    color: ${theme.colors.font.white};
  }
`

ProfessionalAgreementBanner.Link = styled(Link)`
  flex-grow: 1;
  display: inline-block;
  color: ${({ isWhite }) => (isWhite ? '0076DF' : 'white')};
  font-weight: bold;
  text-decoration: none;
`

ProfessionalAgreementBanner.text = Text
ProfessionalAgreementBanner.button = CloseButton
export default ProfessionalAgreementBanner
